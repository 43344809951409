import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer/Footer';
import { Outlet } from 'react-router-dom';


const Layout = () => {
    return (
        <>
            <Header />
            <main>
                <Outlet />
            </main>
          <Footer />
         
          
        </>
    );
};

export default Layout;
