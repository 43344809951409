import { useState, useCallback, useEffect } from 'react';
import { useWaitForTransactionReceipt } from 'wagmi';
import { showPromise, showError, showFulfill } from '../../utils/pageHelpers';

export const useCustomTransaction = () => {
  const [transactionHash, setTransactionHash] = useState(null);
  const [isWaiting, setIsWaiting] = useState(false);
  const [promiseResolve, setPromiseResolve] = useState(null);

  const { data: receipt, isSuccess, isError, error } = useWaitForTransactionReceipt({
    hash: transactionHash,
    enabled: !!transactionHash,
  });

  const handleTransaction = useCallback((hash) => {
    setTransactionHash(hash);
    setIsWaiting(true);
    return new Promise((resolve) => {
      setPromiseResolve(() => resolve);
    });
  }, []);

  useEffect(() => {
    if (isSuccess && receipt) {
      setIsWaiting(false);
      if (promiseResolve) {
        promiseResolve(receipt);
        // showFulfill('Transaction confirmed successfully');
      }
    } else if (isError) {
      setIsWaiting(false);
      if (promiseResolve) {
        promiseResolve(null);
        showError(error?.message || 'Transaction failed');
      }
    }
  }, [isSuccess, isError, receipt, error, promiseResolve]);

  const waitForTransaction = useCallback((hash, pendingMessage='Transaction submitted. Waiting for confirmation...', errorMessage='Transaction failed', successMessage='Transaction confirmed successfully') => {
    const promise = handleTransaction(hash);
    return showPromise(
      promise,
      pendingMessage,
      errorMessage,
      successMessage
    );
  }, [handleTransaction]);

  return {
    waitForTransaction,
    isWaiting,
  };
};
