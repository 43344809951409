import { nftContract, stakeContract, tokenID } from "../../config"
import { getContract } from "../../utils/getContract"
import sftABI from '../../config/abis/IERC1155.json'

export const fetchAccountNFTBalance = async ({ address }) => {
    const nftInstance = getContract(nftContract, sftABI)
    const nftBalance = await nftInstance.balanceOf(address, 1)
    console.debug("nft balance", nftBalance)
    return nftBalance.toNumber()
}

export const fetchAccountApprove = async ({ address }) => {
    const nftInstance = getContract(nftContract, sftABI)
    const nftApprove = await nftInstance.isApprovedForAll(address, stakeContract)
    return nftApprove
}
