import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAccountApprove, fetchAccountNFTBalance } from "./fetchAccountInfo";

export const fetchAccountInfo = createAsyncThunk("account/fetchAccountIn", async ( address ) => {
    try {
        const nftBalance = await fetchAccountNFTBalance({address})
        const isApproved = await fetchAccountApprove({address})

        return {
            nftBalance: nftBalance,
            isApproved
        }
    } catch(err){
        console.log(err)
        return {
            nftBalance: 0,
            isApproved: false
        }
    }
})