import { createSlice } from "@reduxjs/toolkit";
import { fetchStakeInfo } from "../../state/stake";

const initialState = {
    depositedAt: 0,
    generated: 0,
    lastWithdrawn: 0,
    returned: 0,
    returnedUSDT: 0,
    nftPriceAtStaked: 0,
    isStaked: false,
    maxReturn: 0,
    dwPrice: 0,
    totalDays: 0
}

const stakeSlice = createSlice({
    name: 'stake',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchStakeInfo.fulfilled, (state, action) => {
            const data = action.payload
            state.depositedAt = data.depositedAt
            state.generated = data.generated
            state.lastWithdrawn = data.lastWithdrawn
            state.returned = data.returned
            state.isStaked = data.isStaked
            state.maxReturn = data.maxReturn
            state.dwPrice = data.dwPrice
            state.nftPriceAtStaked = data.nftPriceAtStaked
            state.returnedUSDT = data.returnedUSDT
            state.totalDays = data.totalDays
        })
    }
})

export default stakeSlice.reducer