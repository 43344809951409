import React from 'react'

const Analytics = () => {
    return (
        <>
            <div className="os_row2 osrow statistics_row">
                <div className="col4">
                    <div className="inrInfoBx">
                        <div className="bgbx"></div>
                        <span>
                            <p>Totel Nft staked</p>
                            <strong>212121</strong>
                        </span>
                    </div>
                </div>
                <div className="col4">
                    <div className="inrInfoBx">
                        <div className="bgbx"></div>
                        <span>
                            <p>Totel reward distributed</p>
                            <strong>154543</strong>
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Analytics