import BigNumber from 'bignumber.js'
import {createSlice} from "@reduxjs/toolkit";
import { fetchAccountInfo } from '../../state/account';

const initialState = {
    nftBalance: 0,
    isApproved: false
}

const accountSlice = createSlice({
    name: "account",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchAccountInfo.fulfilled, (state, action) => {
            const data = action.payload;
            state.nftBalance = data.nftBalance
            state.isApproved = data.isApproved
        })
    }
})


export default accountSlice.reducer