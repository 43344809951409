export const stakeContract = "0x8c5b1B361c3F3aF54c4E1Afd1b602ab8c8C125Ec"
export const mglxContract = "0xf1bD5af0629467423Ccc471237742dff21ED3E7f"

export const nftContract = "0x64fbe21c49c12899d3325805bc3dc9fcc0287b57"

export const usdtContract = "0xf1bD5af0629467423Ccc471237742dff21ED3E7f"
export const usdcContract = "0x2791bca1f2de4661ed88a30c99a7a9449aa84174"

export const tokenID = "2"
export const openSeaUrl = "https://opensea.io/assets/matic/0x64fbe21c49c12899d3325805bc3dc9fcc0287b57/2"
export const multiCallAddress = "0x4445286592CaADEB59917f16826B964C3e7B2D36" //"0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B"


export const BASE_BSC_SCAN_URLS = 'https://polygonscan.com'
export const BASE_URL = 'https://nft.techmont.finance/'
export const chainRPC = "https://rpc.ankr.com/polygon"

export const networkName = "Polygon Mainnet"
export const minWithdraw = 10

export const maxCap = 2713.33
export const defaultNetwork = 137