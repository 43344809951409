
import { useMemo } from "react";
import { useContractCall } from "./rkit/useContractCall";
import { erc20Abi } from "viem";
import { useAccount } from "wagmi";
import { useTMTAccount } from "./rkit/useTMTAccount";

export const useTokenBalance = (tokenAddress) => {
    const { address } = useTMTAccount();
    const {data} = useContractCall({
        address: tokenAddress,
        abi: erc20Abi,
        method: "balanceOf",
        args: [address]
    })

    return useMemo(() => (
        {
            balance: (data)
        }
    ), [data])
}