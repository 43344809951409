import { CContainer } from "@coreui/react";
import React from "react";
import Analytics from "./Analytics";
import Admintable from "./Admintable";
const Adminpanel = () => {
  const contractAddress = "0xf1bD5af0629467423Ccc471237742dff21ED3E7f";
  const shortenedAddress = `${contractAddress.slice(
    0,
    5
  )}...${contractAddress.slice(-5)}`;
  return (
    <>
      <section className="admin-section ">
        <CContainer>
          <Analytics />
          <div className="inrInfoBx ">
            <div className=" admin-box ">
              <div className="form-container">
                <h4>CLAIM</h4>
                <div className="formtext">
                  <label> Nft staked</label>

                  <button className='tech_btn mr-2' >
                    <i></i>
                    <i></i>
                    <span>claim</span>
                  </button>
                </div>
                <div className="form-field">
                  <div className="">

                    <label> Address :</label>
                    <a
                      href={`https://polygonscan.com/token/${contractAddress}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline"
                    >
                      {shortenedAddress}
                    </a>
                  </div>
                  <button className='tech_btn mr-2' >
                    <i></i>
                    <i></i>
                    <span>Deposit</span>
                  </button>
                </div>
                <div className="form-field">
                  <label>Whitelist Address :</label>
                  <input
                    type="text"
                    placeholder="Enter Whitelist Address"
                  />
                </div>
              </div>
            </div>
          </div>
          <Admintable />
        </CContainer>
      </section>
    </>
  );
};

export default Adminpanel;