import React from 'react';

const Admintable = () => {
  const tableData = [
    { srNo: 1, walletAddress: '0x123...abc', stakeDate: '2024-09-10', expiryDate: '2024-12-10', rewardGenerated: '5.2 ETH' },
    { srNo: 2, walletAddress: '0x456...def', stakeDate: '2024-08-05', expiryDate: '2024-11-05', rewardGenerated: '3.8 ETH' },
    { srNo: 3, walletAddress: '0x789...ghi', stakeDate: '2024-07-12', expiryDate: '2024-10-12', rewardGenerated: '4.0 ETH' }
  ];

  return (
    <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Wallet Address</th>
            <th>Stake Date</th>
            <th>Expiry Date</th>
            <th>Reward Generated</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <React.Fragment key={index}>
              {/* Spacer row */}
              <tr className="spacer">
                <td colSpan="5"></td>
              </tr>
              {/* Data row */}
              <tr>
                <td>{row.srNo}</td>
                <td>{row.walletAddress}</td>
                <td>{row.stakeDate}</td>
                <td>{row.expiryDate}</td>
                <td>{row.rewardGenerated}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Admintable;
