import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

import { ethers } from 'ethers'
import { chainRPC, defaultNetwork } from '../config'

export const ConnectorNames = {
    Injected: "injected",
    WalletConnect: "walletconnect",
}

const POLLING_INTERVAL = 1200

// const chainId = parseInt("137", 10)
// const chainId = parseInt("80001", 10)

const injected = new InjectedConnector({ supportedChainIds: [defaultNetwork] })

const walletconnect = new WalletConnectConnector({
    rpc: { [defaultNetwork]: chainRPC },
    chainId: defaultNetwork,
    qrcode: true,
    pollingInterval: POLLING_INTERVAL,
})

const a = [];
a[ConnectorNames.Injected] = injected
a[ConnectorNames.WalletConnect] = walletconnect

export const connectorsByName = (connectID) => a[connectID]

export const getLibrary = (provider) => {
    const library = new ethers.providers.Web3Provider(provider)
    library.pollingInterval = POLLING_INTERVAL
    return library
}

/**
 * BSC Wallet requires a different sign method
 * @see https://docs.binance.org/smart-chain/wallet/wallet_api.html#binancechainbnbsignaddress-string-message-string-promisepublickey-string-signature-string
 */
export const signMessage = async (provider, account, message) => {
    if (window.BinanceChain) {
        const { signature } = await window.BinanceChain.bnbSign(account, message)
        return signature
    }

    /**
     * Wallet Connect does not sign the message correctly unless you use their method
     * @see https://github.com/WalletConnect/walletconnect-monorepo/issues/462
     */
    if (provider.provider?.wc) {
        const wcMessage = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message))
        const signature = await provider.provider?.wc.signPersonalMessage([wcMessage, account])
        return signature
    }

    return provider.getSigner(account).signMessage(message)
}