import React, { useEffect, useState } from "react";
import { CContainer, CButton, CRow, CCol } from "@coreui/react";
import ReactPlayer from "react-player";
import generated_tmt from "../Assets/images/generated-tmt.png";
import generated_usdt from "../Assets/images/generated-usdt.png";
import pending_days from "../Assets/images/pending-days.png";
import pending_tmt from "../Assets/images/pending-tmt.png";
import pending_usdt from "../Assets/images/pending-usdt.png";
import your_balance from "../Assets/images/your-balance.png";
import { maxCap, nftContract, usdtContract } from "../config";
import { useSelector } from "../redux/store";
import { useStakeInfo } from "../state/stake/hooks";
import { formatNumber } from "../utils";
import { ethers } from "ethers";
import { useNFTPrice } from "../hooks/useNFTPrice";
import { useApprove } from "../hooks/useApprove";
import { useBuyNFT } from "../hooks/useBuyNFT";
import { useTokenBalance } from "../hooks/useTokenBalance";
import { useNFTBalance } from "../hooks/useNFTBalance";
import { useAccountInfo } from "../state/account/hooks";
import Analytics from "./Analytics/Analytics";
import logocoinvideo from "../Assets/images/logocoinvideo.mp4";
import { useUserData } from "../hooks/rkit/useStakeRead";

export const Nftsec = () => {

  // useUserData()
  const { depositedAt, returned, generated, maxReturn, isStaked, totalDays, nftBalance, totalReturnInUsdt, dwPrice } = useUserData();
  const [remainingDays, setRemainingDays] = useState(0);
  const { balance } = useTokenBalance(usdtContract);

  useEffect(() => {
    console.log(totalDays);

    const perDayGeneration = 6.20732; // tokens per day
    const perDayUSDT = perDayGeneration * dwPrice;

    console.log("perday",perDayUSDT);
    console.log("totalreturn",totalReturnInUsdt);
    console.log("generated",generated);
    
    if (perDayUSDT > 0) {
      const totalReturnUSDT = parseFloat(totalReturnInUsdt);
      const calculatedRemainingDays = (totalReturnUSDT - parseFloat(ethers.utils.formatEther(generated))) / perDayUSDT;
      setRemainingDays(calculatedRemainingDays);
    }
  }, [totalDays, dwPrice, totalReturnInUsdt, generated]);

  useEffect(() => {
    console.log('Remaining days:', remainingDays);
  }, [remainingDays]);

  return (
    <div>
      <section className="nftsec">
        <CContainer>
          <div className="listViewBx ms:mt-5 ">
            <div className="mainsec">
              <div className="text-center mb-5">
                <h2>Stake Statistics</h2>
              </div>
              <ReactPlayer
                className="color-changing-box d-lg-none"
                url={logocoinvideo}
                width="500"
                height="600"
                muted
                loop
                playing="true"
                playsinline="true"
              />
              <div className="os_row2 osrow statistics_row">
                <div className="col4">
                  <div className="inrInfoBx">
                    <div className="bgbx"></div>
                    <img src={generated_usdt} alt="Generated" />
                    <span>
                      <p>Generated USDT</p>
                      <strong>
                        {generated ? (formatNumber(generated) * dwPrice).toPrecision(3) : "0"}
                      </strong>
                    </span>
                  </div>
                </div>
                <div className="col4 icons">
                  <ReactPlayer
                    className="color-changing-element"
                    url={logocoinvideo}
                    width="500"
                    height="600"
                    muted
                    loop
                    playing="true"
                    playsinline="true"
                  />
                </div>
                <div className="col4">
                  <div className="inrInfoBx">
                    <div className="bgbx"></div>
                    <img src={generated_tmt} alt="Generated" />
                    <span>
                      <p>Generated TMT</p>
                      <strong>
                        {generated ? formatNumber(generated) : "0"}
                      </strong>
                    </span>
                  </div>
                </div>
                <div className="col4">
                  <div className="inrInfoBx">
                    <div className="bgbx"></div>
                    <img src={pending_days} alt="Collected" />
                    <span>
                      <p>Pending Days</p>
                      <strong>
                        {Math.round(remainingDays)}
                      </strong>
                    </span>
                  </div>
                </div>
                <div className="col4 icons"></div>
                <div className="col4">
                  <div className="inrInfoBx">
                    <div className="bgbx"></div>
                    <img src={pending_tmt} alt="Pending Icon" />
                    <span>
                      <p>Pending TMT</p>
                      <strong>
                        {depositedAt == 0
                          ? "0"
                          : `${parseFloat(
                            (
                              maxReturn -
                              parseFloat(ethers.utils.formatEther(returned))
                            ).toFixed(2)
                          ).toLocaleString()}`}
                      </strong>
                    </span>
                  </div>
                </div>
                <div className="col4">
                  <div className="inrInfoBx">
                    <div className="bgbx"></div>
                    <img src={pending_usdt} alt="Pending Icon" />
                    <span>
                      <p>Pending USDT</p>
                      <strong>
                        {depositedAt == 0
                          ? "0"
                          : `${parseFloat(
                            (
                              totalReturnInUsdt -
                              parseFloat(ethers.utils.formatEther(returned))
                            ).toFixed(2)
                          ).toLocaleString()}`}
                      </strong>
                    </span>
                  </div>
                </div>
                <div className="col4 icons"></div>
                <div className="col4">
                  <div className="inrInfoBx">
                    <div className="bgbx"></div>
                    <img src={your_balance} alt="Pending Icon" />
                    <span>
                      <p>Your TMT Balance</p>
                      <strong>
                        {balance ? formatNumber(balance) : "0"}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CContainer>
      </section>
      <Analytics />
    </div>
  );
};

export default Nftsec;
