import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Pages/Layout';
import Home from './Pages/Home';
import About from './Pages/About';
import StarfieldAnimation from 'react-starfield-animation'
import { Buffer } from 'buffer';
import '@coreui/coreui/dist/css/coreui.min.css'
import BigBangStarField from 'react-big-bang-star-field'
import '../src/Assets/css/main.css'
import '../src/Assets/css/_media.scss'

import { Web3ReactProvider } from '@web3-react/core';
import { Provider } from "react-redux"
import store from "./redux/store"
import { Toaster } from "react-hot-toast";

import 'sweetalert2/src/sweetalert2.scss'
import { getLibrary } from './utils/web3React';

import useDirectConnect from './hooks/useDirectConnect'
import loader from '../src/Assets/images/logo.png'
import Adminpanel from './Components/adminpanel/Adminpanel';

import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultConfig,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import {
  polygon
} from 'wagmi/chains';
import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";

const projectID = '4c63a7a59641d136be1356297b16fd5c'

const config = getDefaultConfig({
  appName: 'TMT',
  projectId: projectID,
  chains: [polygon],
  ssr: true,
});

const queryClient = new QueryClient();

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="admin-panel" element={<Adminpanel />} />
      </Route>
    </Routes>
  )
}

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  window.Buffer = Buffer
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
            <BrowserRouter>
            {loading ? (
                <div className="loader-container">
          <span class="loader">
          <img  src={loader} ></img>

          </span>
              </div>
              ) : (
              <AppRoutes />
              )}
            </BrowserRouter>
            <Toaster />
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
