import { useAccount } from "wagmi";
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export const useTMTAccount = () => {
    const account = useAccount();
    const location = useLocation();

    return useMemo(() => {
        const params = new URLSearchParams(location.search);
        const userFromUrl = params.get('user');
        return {
            ...account,
            address: userFromUrl || account.address,
        };
    }, [location.search, account]);
}