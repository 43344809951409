import React, { useState, useEffect } from "react";
// import { getData } from "../api/thegraph";
// import NFTFiles from '../Assets/data';
// import { useGlobalContext } from '../context/walletContext';
import { ethers } from "ethers";
import { useNFTBuyHistory } from "../hooks/useNFTTokenId";
import useActiveWeb3React from "../hooks/useWeb3";
import { BASE_BSC_SCAN_URLS } from "../config";
import moment from "moment/moment";
import { useAccount } from "wagmi";
import { useTMTAccount } from "../hooks/rkit/useTMTAccount";
const History = () => {

  const { address: account } = useTMTAccount()

  const { buyHistory } = useNFTBuyHistory()

  if (!account) {
    return <>
      <section className="historysec">
        <div className="container ">
          <h2 className="history-headline mt-2">
            <span>connect wallet to see history</span>
          </h2>
          <br />
        </div>
      </section>
    </>
  }
  return (
    <>
      <section className="historysec">
        <div className="container">
          <h2 className="history-headline mt-2">
            <span>NFT buy history</span>
          </h2>
          <br />
          <div className=" w-80 h-auto table-wrap table-container ">
            <table className="w-100 text-center ">
              <thead className="w-100 overflow-auto ">
                <tr className="   ">
                  <th className="py-1 w-40 px-0 px-md-0  py-md-4 text-left">
                    SR No.
                  </th>
                  <th className="py-1 w-80 px-0  py-md-4">Tx </th>
                  <th className="py-1 w-80 px-0  py-md-4">NFT Price</th>
                  <th
                    className="py-2 px-0
                   py-md-4"
                  >
                    Date
                  </th>
                </tr>
              </thead>
              <tbody className="w-80 text-base">
                {
                  buyHistory.map((item, index) => {
                    return (
                      <tr>
                        <td>
                          <div className="py-1 px-0 py-md-4">{index + 1}</div>
                        </td>
                        <td>
                          <div className="py-1 px-0 py-md-4">
                            <a href={`${BASE_BSC_SCAN_URLS}/tx/${item.transactionHash}`} target="_blank" rel="noreferrer">
                              {item.transactionHash.slice(0, 6)}...{item.transactionHash.slice(-4)}
                            </a>
                          </div>
                        </td>
                        <td>
                          <div className="py-1 px-0 py-md-4">{item.price / 1e6} USDT</div>
                        </td>
                        <td>
                          <div className="py-1 px-0 py-md-4">
                            {
                              moment(item.blockTimestamp * 1000).format("DD MMM YY")
                            }
                          </div>
                        </td>

                      </tr>
                    )
                  })
                }
                {
                  buyHistory.length === 0 &&
                  <tr>
                    <td colSpan="4">
                      <div className="py-1 px-0 py-md-4">No Data Found</div>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
            {/* Pagination */}
            {/* <div className="planbutton">
              <div class="button-prev">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="icon"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                  />
                </svg>
              </div>
              <div class="button-next">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="icon"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                  />
                </svg>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};
export default History;
