import { useCallback, useEffect, useMemo, useState } from "react"
import useActiveWeb3React from "./useWeb3"
import axios from "axios"
import { useAccount } from "wagmi"
import { useTMTAccount } from "./rkit/useTMTAccount"

const graphQlUrl = "https://api.studio.thegraph.com/query/76981/tmt-nft-staking/version/latest"

const query = (para) => `
query MyQuery {
    userNFTDatas(where:{user:"${para}"}) {
      user
      nftAddress
      tokenIds
    }
}`

const buyHistoryQuery = (para) => `
query MyQuery {
    nftboughts(where: {buyer: "${para}"}, orderBy: blockTimestamp, orderDirection: desc, first: 10) {
      buyer
      price
      tokenId
      transactionHash
      blockTimestamp
    }
}`

export const useNFTTokendId = () => {
    const [tokenIds, setTokenIds] = useState([])
    const { address: account } = useTMTAccount()

    const fetchUsingGraphqlQuery = useCallback(async () => {
        const response = await axios.post(graphQlUrl, { query: query(account) })
        console.debug(response.data)
        const { data } = response.data
        const { userNFTDatas } = data ?? {userNFTDatas: {tokenIds: []}}
        const { tokenIds } = userNFTDatas[0] ?? { tokenIds: [] }
        setTokenIds(tokenIds)
    }, [account])

    useEffect(() => {
        if (account) {
            fetchUsingGraphqlQuery()
        }
    }, [account, fetchUsingGraphqlQuery])

    useEffect(() => {
        const refreshInterval = setInterval(() => {
            fetchUsingGraphqlQuery()
        }, 10000)
        return () => clearInterval(refreshInterval)
    }, [fetchUsingGraphqlQuery])

    return useMemo(() => {
        return { tokenIds }
    }, [tokenIds])


}

export const useNFTBuyHistory = () => {
    const [buyHistory, setBuyHistory] = useState([])
    const { address: account } = useTMTAccount()

    const fetchUsingGraphqlQuery = useCallback(async () => {
        const response = await axios.post(graphQlUrl, { query: buyHistoryQuery(account) })
        console.debug(response.data)
        const { data } = response.data
        const { nftboughts } = data ?? {nftboughts:[]}
        setBuyHistory(nftboughts)
    }, [account])

    useEffect(() => {
        if (account) {
            fetchUsingGraphqlQuery()
        }
    }, [account, fetchUsingGraphqlQuery])

    useEffect(() => {
        const refreshInterval = setInterval(() => {
            fetchUsingGraphqlQuery()
        }, 10000)
        return () => clearInterval(refreshInterval)
    }, [fetchUsingGraphqlQuery])

    return useMemo(() => {
        return { buyHistory }
    }, [buyHistory])
}