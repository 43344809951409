import { useReadContract } from "wagmi"

export const useContractCall = ({address, abi, method, args}) => {
    const { data, isLoading, isPending, isError, error } = useReadContract({
        address: address,
        abi: abi,
        functionName: method,
        args: args,
        query: {
            enabled: true,
            refetchInterval: 3000,
            refetchIntervalInBackground: true
        }
    })
    return { data, isLoading, isPending, isError, error }
}